<template>
    <el-card class="list"  shadow="always">
        <el-row slot="header" class="clearfix">
            <el-col :span="16">项目名称</el-col>
            <el-col :span="8">发布时间</el-col>
        </el-row>
        <div style="min-height:100px">
            <a 
                class="item"
                v-for="(item, index) in data"
                :key="index"
                :href="href(item)"
                target="_blank"
                >
                    <el-row>
                        <el-col :span="20">
                            <div class="title">
                                <slot :row="item">
                                    {{item[props.title]}}
                                </slot>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="addtime">
                                {{item[props.time]}}
                            </div>
                        </el-col>
                        
                    </el-row>
                </a>
        </div>
        

        <a class="btn" href="/business">查看更多</a>

    </el-card>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        href: {
            type: Function,
            default: (row) => {
                return ``;
            },
        },
        props: {
            type: Object,
            default: () => ({
                title: 'title',
                time: 'time',
            }),
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .list{
        margin-top: 30px;
        padding: 0px 40px;
        ::v-deep{
            .el-card__header{
                padding: 30px 0;
                .el-col{
                    font-size: 20px;
                    font-weight: 600;
                }
                .el-col:last-child{
                    text-align: right;
                }
            }
            .el-card__body{
                padding : 0;
            }
        }
        .item {
            display: block;
            overflow: hidden;
            height: 58px;
            line-height: 58px;
            color: #666666;
            border-bottom: 1px solid #eeeeee;
       

            &:hover{
                color: $primaryColor;
            }

            .addtime{
                float: right;
            }
        }
        .btn{ 
            display: block; 
            width: 80px;
            height: 34px;
            text-align: center;
            line-height: 34px;
            background: #64ad51;
            color: #fff;
            margin: 45px auto;
        }
    }
</style>