<template>
    <div class="index">
        <banner></banner>
        
        <!-- <div class="content sec2">
            <el-row :gutter="12">
                <el-col :span="18">
                    <news></news>
                </el-col>
                <el-col :span="6">
                    <enters></enters>
                </el-col>
            </el-row>
        </div> -->
        
        <business></business>

        <div style="background: #fcfcfc; padding-top:60px">
            <news></news>
        </div>
    </div>
</template>

<script>
import Banner from './index/sections/banner';
import News from './index/sections/news';
import Enters from './index/sections/enters';
import Business from './index/sections/business';

import appNode from '@js/app-node';

export default {
    components: {
        Banner,
        News,
        Enters,
        Business,
    },
    
    data: () => ({
        
    }),
    methods: {
        
    },
    mounted() {
        
    }
}
</script>

<style lang="scss" scoped>
    .sec2{
        margin-top: 60px;
    }
</style>
