<template>
    <div class="enters">
        <div class="lr">

            <el-row :gutter="6">
                <el-col
                :span="24"
                >
                    <a 
                    class="item primary"
                    @click="login"
                    >
                        登录
                    </a>
                </el-col>

                <el-col
                :span="24"
                >
                    <a 
                    class="item info"
                    href="/register"
                    target="_blank"
                    >
                        注册
                    </a>
                </el-col>
            </el-row>
            
        </div>


        <div class="guide">
            <a 
            class="item"
            v-for="(item, index) in guide"
            :key="index"
            :href="item.url"
            target="_blank"
            >
                <img :src="item.icon" :alt="item.title" />
                {{item.title}}
            </a>
        </div>
    </div>
</template>

<script>
import CONFIG from '@config/config';

import advBbJpg from '../assets/adv-bb.jpg';
import advCaJpg from '../assets/adv-ca.jpg';

import iconGuide from '../assets/icon/guide.png';
import iconTel from '../assets/icon/tel.png';

export default {
    data: () => ({


        guide: [
            {
                title: '关于我们',
                icon: iconGuide,
                url: '/about'
            },
            {
                title: '办事指南',
                icon: iconTel,
                url: '/guide'
            }
        ],
        head:CONFIG.head,
    }),
    methods: {
        // 登录
        login() {
            window.open('/hbiddingWeb/pages/chooseusersub.html?indexUrl=' + window.btoa(window.location.href)+'&title=' + escape(this.head.title)+'&plan='+CONFIG.head.planname)
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .enters{
        .lr{
            overflow: hidden;
            margin: -3px 0;

            .item{
                display: block;
                width: 100%;
                box-sizing: border-box;
                float: left;
                font-size:18px;
                font-weight: bold;
                color: white;
                padding: 16px 0;
                text-align: center;
                margin: 5px 0;
                box-sizing: border-box;
                border: 1px solid $primaryColor;
                cursor: pointer;
                transition: all .3s;

                &.primary{
                    background: $primaryColor;

                    &:hover{
                        background: $primaryColor9;
                    }
                }

                &.info{
                    background: white;
                    color: $primaryColor;

                    &:hover{
                        background: $primaryColor;
                        color: white;
                    }
                }
            }
        }

        .ads{
            margin-top: 12px;

            .item{
                display: block;
                height: 60px;
                position: relative;
                cursor: pointer;
                overflow: hidden;

                img{
                    width: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transition: all .3s;
                }

                &:hover {
                    img{
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                }

                & + .item{
                    margin-top: 12px;
                }
            }
        }

        .guide {
            margin-top: 20px;
            background: white;
            overflow: hidden;

            .item{
                float: left;
                width: 50%;
                font-size: 16px;
                font-weight: bold;
                line-height: 1em;
                padding: 22px 0;
                text-align: center;
                color: #1b1b1b;
                letter-spacing: 1px;
                position: relative;
                cursor: pointer;

                & + .item:before{
                    content: '';
                    display: block;
                    width: 1px; 
                    height: 38px;
                    background: #dbdbdb;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                img{
                    vertical-align: middle;
                    width: 26px;
                    height: 26px;
                }

                &:hover{
                    img{
                        transform: scale(1.1);
                        transition: all .3s;
                    }
                }
            }
        }
    }
</style>