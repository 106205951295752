<template>
    <div class="content news">
        <h2 class="title">
            服务信息
        </h2>
        <div class="newsCon">
            <el-row :gutter="40">
                <el-col :span="12" v-for="(item,index) in tableData" :key="index" class="newslist">
                    <el-row :gutter="20">
                        <el-col :span="3">
                            <div class="right">
                                <h4>{{item.time}}</h4>
                                <p>{{item.year}}</p>
                            </div>
                        </el-col>
                        <el-col :span="21">
                            <a :href="hrefBuilder(item)">
                                <div class="left">
                                    <h4>{{item.title}}</h4>
                                    <p v-html="item.infocontentstr"></p>
                                </div>
                            </a>
                            
                        </el-col>
                    </el-row>
                    
                </el-col>
                
            </el-row>
            
        </div>

        
    </div>
</template>

<script>
import CONFIG from '@config/config';


export default {
    components: {
    },
    data() {
        return {
            tableData: [],

            loadingController: false,
        };
    },
    methods: {
        queryData() {
            this.loadingController = true;

            this.$get(`${this.$store.state.api.webUrl}/index/information/getnewsnotice.json`,{
                featurecode:CONFIG.head.planname,
                featureonly: 1
            }, data => {
                data.forEach(function(item) {
                    var arr = [];
                    arr = item.infodate.split('-')
                    item.year = arr[0];
                    item.time = arr[1] + '-' + arr[2];
                });
                this.tableData = data;
                this.loadingController = false;
            });
        },
        hrefBuilder(item) {
            var search = toSearch({
                infoid: item.infoid,
            });

            return `/news/detail${search}`;
        },
    },
    mounted() {
        this.queryData();
    },
};
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .news{

        min-height: 245px;
        margin-bottom: 60px;
        h2{
           font-size: 32px;
           font-weight: 400;
           text-align: center;
        }
    }

    .newsCon{
        .newslist{
            margin-top: 60px;
            h4{
                font-weight: 400;
                margin-bottom: 15px;
                
            }
            .right h4{
                color: #333;
                text-align: right;
                font-size: 18px;
            }
            .left h4{
                overflow: hidden;
                width: 400px;
                font-size: 16px;
                color: #333;
                height: 18px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            p{
                color: #8d8d8d;
            }
            .right p{
                text-align: right;
            }
            .left p{
                height: 50px;
                width: 400px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                line-height: 24px;
            }
        }
        
    }
</style>