<template>
    <section class="banner">
        
        <el-carousel class="banner_img" trigger="click">
            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                <a :href="item.linkurl" target="_blank">
                    <img :src="item.linkimg">
                </a>
            </el-carousel-item>
        </el-carousel>
        <!--
        <div class="data">
            <div class="title">
                今日交易
            </div>

            <div class="con">
                <div 
                class="item"
                v-for="(item, index) in dataList"
                :key="index"
                >
                    <div class="num">
                        {{item.num}}
                    </div>
                    <div class="text">
                        {{item.title}}
                    </div>
                </div>
            </div>
        </div>-->
    </section>
</template>

<script>
import CONFIG from '@config/config';
import appNode from '@js/app-node';

export default {
    data() {
        return {
            title: CONFIG.head.title,

            hotList: ['政府采购', '企业采购'],
            bannerList:[{
                linkimg:'/images/banner1.jpg',
                linkurl:''
            }],
            dataList: [],
        };
    },
    methods: {
      
    },
    mounted() {
        var that = this;


        this.$get(`${this.$store.state.api.webUrl}/index/information/indexinfoextra.json`, {
            featurecode:CONFIG.head.planname,
        }, data => {
            that.dataList = [ {
                title: '采购公告',
                num: data.jinrijiaoyi.caigougonggaocount,
            },
            {
                title: '开标',
                num: data.jinrijiaoyi.kaibiaocount,
            },
            {
                title: '评标',
                num: data.jinrijiaoyi.pingbiaocount,
            },
            {
                title: '中标',
                num: data.jinrijiaoyi.zhongbiaocount,
            }];
        });
        // 获取轮播图
        this.$get(`${this.$store.state.api.webUrl}/index/information/getcarouselpic.json`,  {
            featurecode:CONFIG.head.planname,
        }, data => {
            if(data.length>0){
                this.bannerList = data;
            }
            
        })
    },
};
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .banner{
        box-sizing: border-box;
        height: 470px;
        background-size: cover;
        background-position: center cetner;
        position: relative;

        .banner_img{
            height: 470px;
            width: 100%;

            ::v-deep .el-carousel__container{
                height: 470px;
            }
            img{ width: 100%; }
        }

      
        .data{
            width: 1200px;
            overflow: hidden;
            cursor: default;
            text-align: center;
            background: white;
            padding: 24px 0 18px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            box-shadow: 0 5px 5px #E6E7E9;
            z-index: 10;

            .title{
                float: left;
                font-size: 18px;
                color: #1b1b1b;
                width: 126px;
                margin-right: -126px;
                margin-top: 10px;
                position: relative;

                &:after{
                    content: '';
                    display: block;
                    width: 1px;
                    height: 28px;
                    background: #a4a4a4;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .con{
                margin-left: 126px;
                overflow: hidden;

                .item{
                    float: left;
                    width: 25%;

                    .num {
                        font-size: 26px;
                        font-weight: bold;
                        color: $primaryColor;
                        line-height: 1em;
                    }

                    .text{
                        line-height: 1em;
                        margin-top: 10px;
                        color: #3c3c3c;
                    }
                }
            }
        }
    }
</style>