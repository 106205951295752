<template>
    <card class="content business">
        <span slot="title">
            交易信息
        </span>

        <!-- <el-link 
        slot="title-r"
        :underline="false" 
        type="primary"
        href="/business"
        >
            查看更多>
        </el-link> -->

        <search-bar
            v-model="pgData"
            @change="queryData"
        ></search-bar>

        <list
        :data="tableData"
        :props="{title:'GGNAME', time:'GGFBTIME'}"
        :href="hrefBuilder"
        >
            <div class="businesslist" slot-scope="scope">
                【{{scope.row.TYPENAME}}】
                {{scope.row.GGNAME}}
            </div>
        </list>
    </card>
</template>

<script>
import CONFIG from '@config/config';

import List from '@components/sys/list';
import Card from '@components/sys/card';

import SearchBar from '../../business/components/search-bar';

export default {
    components: {
        List,
        SearchBar,
        Card,
    },
    data() {
        return {
            pgData: {
                type: 'all',
                industrycategory: '',
                citycode: CONFIG.head.citycode,
                featurecode:CONFIG.head.planname,
            },

            tableData: [],

            loadingController: false,
        };
    },
    methods: {
        queryData() {
            this.loadingController = true;

            this.$get(`${this.$store.state.api.webUrl}/index/information/getjyinfo.json`, {
                ...this.pgData,
                maxrow: 8,
            }, data => {
                this.tableData = data;
                
                this.loadingController = false;
            });
           
        },
        hrefBuilder(item) {
            var search = toSearch({
                ggcode: item.GGCODE,
                categoryid: item.GGTYPE
            });

            if (/SD/.test(item.GGCODE)) {
                return `/business/detail-sd${search}`
            }

            return `/business/detail${search}`;
        },
    },
    mounted() {
        this.queryData();
    },
};
</script>

<style lang="scss" scoped>
.business{
    padding-top: 60px;
    ::v-deep .head{
        text-align: center;

        .title{
            color: #333;
            font-size: 32px;
            font-weight: 400;
        }
    }
}

.businesslist{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;
}

</style>