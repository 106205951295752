<template>
    <div class="search-bar">
        <div class="search-item">
            <div class="label">招标内容：</div>

            <div class="list">
                <div 
                class="item" 
                v-for="(item, index) in ggType"
                :class="[item.type===model.type ? 'active' : '']" 
                @click="updateModel('type', item.type)"
                :key="index"
                >
                    {{item.name}}
                </div>
            </div>
        </div>

        <div class="search-item">
            <div class="label">项目类型：</div>
            
            <div class="list">
                <div 
                class="item" 
                v-for="(item, index) in proTypes" 
                :class="[item.value===model.industrycategory ? 'active' : '']" 
                @click="updateModel('industrycategory', item.value)"
                :key="index"
                >
                    {{item.label}}
                </div>
            </div>
        </div>

        <!-- <div class="search-item">
            <div class="label">项目区域：</div>
            
            <div class="list">
                <div 
                class="item" 
                v-for="(item, index) in cityList" 
                :class="[item.cityid===model.citycode ? 'active' : '']" 
                :key="index"
                @click="updateModel('citycode', item.cityid)"
                >
                    {{item.name}}
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import CONFIG from '@config/config';

import MODEL_MIXIN from '@mixins/model';

export default {
    mixins: [MODEL_MIXIN],
    props: {
        value: {
            type: Object,
            default: () => ({
                type: '',
                industrycategory: '全部',
                citycode: CONFIG.head.citycode,
            }),
        },
    },
    data() {
        return {
            ggType: [
                {
                    type: 'all',
                    name: '全部',
                    id:'0'
                },
                { 
                    name: '招标公告',
                    type:'zbgg',
                    id:'1',
                },
                
                { 
                    name: '结果公示', 
                    type: 'hxrgs',
                    id:'4',
                },
                { 
                    name: '中标公告', 
                    type: 'zhongbiaogg',
                    id:'5',
                },
               
            ],
            proTypes: [
                {
                    value: '',
                    label: '全部'
                },
                {
                    value: '工程',
                    label: '工程'
                },
                {
                    value: '货物',
                    label: '货物'
                },{
                    value: '服务',
                    label: '服务'
                },{
                    value: '招商',
                    label: '招商'
                },
            ],
            cityList: [],
        };
    },
    methods: {
        queryCity() {
            this.$get(`${this.$store.state.api.webUrl}/index/information/getcitylist.json`, {
                parentid: CONFIG.head.citycode,
            }, data => {
                this.cityList = data;
                this.cityList.unshift({
                    name: '全省', 
                    cityid: CONFIG.head.citycode,
                });
            });
        },
        updateModel(key, value) {
            this.model[key] = value;
            this.$emit('change');
        },
    },
    mounted() {
        // this.queryCity();
    }
};
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .search-bar{
        cursor: default;

        .search-item{
            overflow: hidden;
            line-height: 1em;
            margin-bottom: 10px;
            .label{
                float: left;
                color: #333;
                margin: 6px 0;
                margin-right: -80px;
                padding: 8px 5px;
                font-weight: 600;
            }

            .list{
                margin-left: 80px;
                overflow: hidden;

                .item{
                    float: left;
                    color: #333;
                    margin: 6px 0;
                    margin-left: 25px;
                    padding: 8px 10px;
                    cursor: pointer;
                    transition: all .3s;

                    &:not(.active):hover {
                        background: $primaryColor1;
                    }

                    &.active{
                        background: $primaryColor;
                        color: white;
                    }
                }
            }
        }
    }
</style>